import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export default function PolicyPage({ data }: { data: any }) {
  const { content, heading, title } = data.graphCmsTermsAndPrivacyPolicy;

  return (
    <>
      <SEO title={title}></SEO>
      <Layout>
        <h2 className="text-center mb-36">{heading}</h2>
        <article dangerouslySetInnerHTML={{ __html: content.html }}></article>
      </Layout>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    graphCmsTermsAndPrivacyPolicy(
      slug: { eq: "terms-and-conditions" }
      language: { eq: $language }
    ) {
      content {
        html
      }
      heading
      title
    }
  }
`;
